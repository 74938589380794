import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

const ViewAd = ({ args, Component, onSnackBar }) => {
  const [ob, setOb] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    // height: 600,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // alignItems: 'center',
    // p: 4,
    // overflow: 'hidden',
    borderRadius: 2,
  };

  const BgblackTooltips = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  function withoutProperty(obj, property) {
    const { [property]: unused, ...rest } = obj;

    return rest;
  }
  const handleClose = () => {
    setOpen(!open);
    // onSnackBar && onSnackBar(true)
  };
  const updateHandler = () => {
    setOpen(!open);
    setData(withoutProperty(args, 'column'));
  };
  // const onCancel = (cancel) => {
  //   cancel && handleClose();
  // };
  console.log('Print ARGS --------', args);

  return (
    <div>
      <BgblackTooltips title="View" arrow>
        {/* <i className="fa-solid fa-eye text-[#203f74] text-base" onClick={updateHandler} /> */}
        <p className="font-sans font-semibold text-sm text-blue-500 cursor-pointer underline" onClick={updateHandler}>
          {args['Ad Entity']}
        </p>
      </BgblackTooltips>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="overflow-y-scroll"
      >
        {/* {(Component) && Component(args,onCancel,onSnackBar)} */}
        {/* <Box sx={style} className="bg-white  min-h-[90%] w-[95%] overflow-scroll "> */}
        <Box sx={style} className="bg-white  min-h-[75%] w-[75%] overflow-scroll bg-orange-100">
          <CloseIcon
            className="absolute text-orange-500 top-1 right-1 w-8 h-8 cursor-pointer hover:bg-orange-200 transition-all duration-300 rounded-full"
            onClick={handleClose}
          />
          <div class=" py-8">
            <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div class="flex flex-col md:flex-row -mx-4">
                <div class="md:flex-1 px-4 bg-white">
                  <div class="rounded-lg mb-4 max-h-72 min-h-64 bg-white">
                    <ImageGallery
                      lazyLoad showFullscreenButton={false} showPlayButton={false}
                      items={[args?.Image]?.map((d) => ({ original: d, thumbnail: d }))}
                      // originalHeight={'100px'}
                      renderItem={(item) => (
                        <div className="image-gallery-image bg-white">
                          <img src={item.original} alt={item.originalAlt} 
                            // className="max-w-fit min-w-full h-[450px]" 
                            className="max-h-[450px] bg-white" 
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div class="md:flex-1 px-4 max-h-fit overflow-scroll">
                  <h2 class="text-xl font-bold text-gray-800 mb-2">{args['Ad Entity']}</h2>
                  {args?.['List Id'] ? 
                    <p class="text-blue-600 text-sm mb-4 underline">
                      <a href={`https://desiinvidesi.com/listing/${args?.['List Id']}`} 
                        target='_blank' rel="noreferrer" className="text-blue-600 underline" >
                        Click here to view the content
                      </a>
                    </p>
                    :
                    <p class="text-gray-600 text-sm mb-4">
                      <a href={`${args?.['Ad Entity']}`} rel="noreferrer" target='_blank'>
                        Click here to view the content
                      </a>
                    </p>
                  }
                  <div class="flex mb-2 items-center gap-4">
                    {/* <div>
                      <span class="font-bold text-gray-700  ">Keywords: </span>
                      <span class="text-gray-600  ">{args?.keyword.replaceAll('+', ',')}</span>
                    </div> */}
                    <div>
                      <span class="font-bold text-gray-700  ">Created At: </span>
                      <span class="text-gray-600  ">{args?.['Created At']}</span>
                    </div>
                  </div>
                  <div class="flex mb-2">
                    {args?.['Start Time'] && (
                      <div>
                        <span class="font-bold text-gray-700">Start Time: </span>
                        <span class="text-gray-600  ">{args?.['Start Time']}</span>
                      </div>
                    )}
                  </div>
                  <div class="flex mb-2">
                    {args?.['End Time'] && (
                      <div>
                        <span class="font-bold text-gray-700">End Time: </span>
                        <span class="text-gray-600  ">{args?.['End Time']}</span>
                      </div>
                    )}
                  </div>
                  <div class="flex mb-2">
                    <div class="mr-4">
                      <span class="font-bold text-gray-700  ">User Name: </span>
                      <span class="text-gray-600  ">{args?.['User Name']}</span>
                    </div>
                  </div>
                  <div class="flex mb-2">
                    <div>
                      <span class="font-bold text-gray-700  ">User Email: </span>
                      <span class="text-gray-600  ">{args?.['User Email']}</span>
                    </div>
                  </div>
                  <div class="flex mb-2 items-center gap-4">
                    <div>
                      <span class="font-bold text-gray-700">Ad Page </span>
                      <p class="text-gray-600   text-sm mt-2 overflow-scroll max-h-32  ">{args?.['Page Name']}</p>
                    </div>
                    <div>
                      <span class="font-bold text-gray-700">Ad Section </span>
                      <p class="text-gray-600   text-sm mt-2 overflow-scroll max-h-32  ">{args?.['Site Location']}</p>
                    </div>
                  </div>
                  {/* <div class="flex mb-2">
                    <div class="mr-4">
                      <span class="font-bold text-gray-700  ">Email: </span>
                      <span class="text-gray-600  ">{args?.Email}</span>
                    </div>
                    <div>
                      <span class="font-bold text-gray-700  ">Phone: </span>
                      <span class="text-gray-600  ">{args?.Phone}</span>
                    </div>
                  </div> */}
                  <div class="flex mb-2">
                    <div>
                      <span class="font-bold text-gray-700  ">Status: </span>
                      <span class="text-gray-600  ">{args?.['Status']}</span>
                    </div>
                  </div>
                  {args?.listing_customeField_data && (
                    <div className="flex mb-2 gap-4 flex-col max-h-52 overflow-scroll">
                      {args?.listing_customeField_data?.map((item) => (
                        <div className="">
                          <span className="font-bold text-gray-700  ">{item.fieldName}: </span>
                          {item.fieldType === 'file' || item.fieldType === 'url' ? (
                            <a href={item.fieldValue} target="_blank" className="text-blue-500 underline">
                              {item.fieldValue}
                            </a>
                          ) : (
                            <span className="text-gray-600">{item.fieldValue}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default ViewAd;
