/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Stack, Container, Typography, Box, CircularProgress } from '@mui/material';
import DataGrid from '../components/Datagrid/Datagrid';
import baseUrl from '../utils/baseUrl';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';

export default function UsersPage() {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [reload, setReload] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    try {
      setLoading(true);
      const stringfyData = await fetch(`${baseUrl}/api/v1/users?limit=100`);
      const arr = await stringfyData.json();
      // console.log('stringfyData ----', arr);
      const modifiedArr = arr?.data?.users.map((field, key) => ({
        'Serial No': key + 1,
        Code: field.id,
        Name: field.name,
        // 'User Name': field.user_name,
        Email: field.email,
        // 'Phone Number': field.phoneNumber,
        // 'Address': field.address,
        'Living City': field.livingCity,
        'Living State': field.livingState,
        'Living Country': field.livingCountry,
        'Origin City': field.originCity,
        'Origin State': field.originState,
        'Origin Country': field.originCountry,
        'Mother Tongue': field.motherTongue,
        // 'Facebook Id': field.fbUrl,
        role: field.Role.role,
        // 'Active': field.active,
        'Create Date': field.createdAt?.substring(0, 10),
        'Update Date': field.updatedAt?.substring(0, 10),
        Status: field.status,
      }));
      // console.log(modifiedArr);
      setAllFields(modifiedArr);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // const component = ()=>{

  // }

  const onClickEvent = () => {};
  const snackBarHandler = (data) => {
    console.log(data);
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    setReload(data.reload);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      <Container>
        {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Stack>
        {allFields?.length > 0 ? (
          <DataGrid
            data={allFields}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            name="/api/v1/users/"
            // componentName="editExpenceForm"
            // viewName="viewExpenseForm"
            openSnackBar={snackBarHandler}
          />
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress /> &nbsp;Loading....
          </Box>
        ) : (
          <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>
        )}
      </Container>
    </>
  );
}
