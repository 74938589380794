/* eslint-disable */
import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Selection,
  //@ts-ignore
  Page,
  //@ts-ignore
  Grid,
  PageSettingsModel,
  Sort,
  Resize,
  Filter,
  FilterSettingsModel,
  RowSelectEventArgs,
  EditSettingsModel,
  Toolbar,
  ColumnChooser,
  TextWrapSettingsModel,
  ForeignKey,
  ExcelExport,
  PdfExport,
} from '@syncfusion/ej2-react-grids';
import '../../css/dataGrid.css';
// import View from "./View";
import Update from './Update';
import { useLocation, useNavigate } from 'react-router-dom';
import ActiveData from './Active';
import { approve, reject, pending } from '../../assets/index';
import { HeaderNotShow, noUpdate } from './DatagridUtil';
import Deactivate from './Deactivate';
import Delete from './Delete';
import { Box, ImageList, ImageListItem, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ViewData from './ViewData';
import ViewAd from './ViewAd';
// import Complete from "./Complete";
// import { object } from "prop-types";

const DataGrid = ({
  data,
  onClickEvent,
  hideTool,
  pagingTool,
  checkBox,
  styleProps,
  width,
  isFilter,
  isView,
  componentName,
  name,
  empGrid,
  deletedData,
  wrap,
  openSnackBar,
  renderComponent,
  // onSnackBar
}) => {
  var header = Object?.keys(data[0]).filter(
    (item) =>
      item !== 'Reference Id' &&
      item !== 'keyword' &&
      item !== 'categoryObject' &&
      item !== 'userObject' &&
      item !== 'subCategoryObject' &&
      item !== 'listing_customeField_data'
  );
  const refOne = useRef(null);

  const [customHeader, setCustomHeader] = useState((isFilter || empGrid) && header);
  const [gridInstance, setGridInstnce] = useState();
  const [imageData, setImageData] = useState([]);
  const [dis, setDis] = useState('');
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = (item) => {
    setImageData(item);
    setOpen(true);
  };
  const handleOpen2 = (item) => {
    setDis(item);
    setOpen2(true);
  };
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const location = useLocation();
  const navigate = useNavigate();

  const rowDataBound = (args) => {
    deletedData &&
      deletedData.forEach((code) => {
        if (args.data['Bill Id'] === code || args.data['Serial No'] === code) {
          args.row.classList.add('changeColor');
        }
      });
  };

  const actionHandler = (args) => {
    // console.log(args)
    return (
      <div>
        <div style={{ display: 'flex', gap: '18px', justifyContent: 'center', alignItems: 'center' }}>
          {/* <View args={args} data={data} name={viewName} /> */}
          {!location.pathname.split('/').some((r) => noUpdate.includes(r)) && (
            <Update
              name={componentName}
              args={args}
              data={data}
              Component={renderComponent && renderComponent}
              onSnackBar={openSnackBar && openSnackBar}
            />
          )}
          {/* {(args.Status === 'deactive' || args.Status === 'pending') && (
            <ActiveData name={name} args={args} onActive={openSnackBar} />
          )} */}
          <ActiveData name={name} args={args} onActive={openSnackBar} onDeActive={openSnackBar} />

          {/* {(args.Status === 'active' || args.Status === 'pending') && (
            <Deactivate name={name} args={args} onDeActive={openSnackBar} />
          )} */}
          {/* {location.pathname.includes('listings') && <ViewData name={name} args={args} onDelete={openSnackBar} />} */}

          {location.pathname.includes('bannerimageupload') && (
            <Delete name={name} args={args} onDelete={openSnackBar} />
          )}
        </div>
      </div>
    );
  };
  const actionViewHandler = (args) => {
    // console.log(args)
    return (
      <div>
        <div style={{ display: 'flex', gap: '24px', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {location.pathname.includes('listings') && <ViewData name={name} args={args} onDelete={openSnackBar} />}
        </div>
      </div>
    );
  };
  const actionViewHandler2 = (args) => {
    // console.log(args)
    return (
      <div>
        <div style={{ display: 'flex', gap: '24px', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {location.pathname.includes('advertiseentity') && <ViewAd name={name} args={args} onDelete={openSnackBar} />}
        </div>
      </div>
    );
  };

  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Normal',
  };

  const filterOptions = { type: 'Excel' };

  const pageOptions = {
    pageSize: window.screen.availHeight < 700 ? 8 : window.screen.availHeight < 900 ? 10 : 12,
    pageSizes: ['5', '10', '12', '25', '50'],
  };
  const dataBound = () => {
    if (!styleProps) {
      gridInstance?.hideScroll();
    }
  };
  function withoutProperty(obj, property) {
    const { [property]: unused, ...rest } = obj;
    return rest;
  }

  const smW = window.screen.width;
  const created = () => {
    //@ts-ignore
    document.getElementById(gridInstance?.element.id + '_searchbar')?.addEventListener('keyup', () => {
      gridInstance?.search(
        //@ts-ignore
        event?.target.value
      );
    });
  };
  let selectedData = [];
  // if(array.length === data.length) array = [];
  const select = [];
  const deselect = [];
  const onCheckboxSelect = (args) => {
    // console.log(args);
    select.push(args.data);
    //@ts-ignore
    args?.data?.length !== gridInstance?.pagerModule.pagerObj.properties.pageSize
      ? selectedData.length === gridInstance?.pagerModule.pagerObj.properties.pageSize
        ? ((selectedData = []), selectedData.push(args.data))
        : args.data.length === data.length ||
          args.data.length ===
            data?.length -
              gridInstance?.pagerModule.pagerObj.properties.pageSize *
                (gridInstance?.pagerModule.pagerObj.currentPage - 1) ||
          args.data.length ===
            gridInstance?.getFilteredRecords()?.length -
              gridInstance?.pagerModule.pagerObj.properties.pageSize *
                (gridInstance?.pagerModule.pagerObj.currentPage - 1)
        ? (selectedData = args.data)
        : selectedData.push(args.data)
      : (selectedData = args.data);
  };
  const onCheckboxDeselect = (args) => {
    //@ts-ignore
    args.data.length === gridInstance?.pagerModule.pagerObj.properties.pageSize ||
    args.data.length === data.length ||
    args.data.length ===
      data?.length -
        gridInstance?.pagerModule.pagerObj.properties.pageSize * (gridInstance?.pagerModule.pagerObj.currentPage - 1)
      ? (selectedData = [])
      : deselect.push(args.data);
    if (selectedData.indexOf(args.data) !== -1) {
      selectedData.splice(selectedData?.indexOf(args.data), 1);
    }
  };
  const clickHandler = (args) => {
    // console.log(args)
    if (gridInstance && args.item.id === 'grid_excelexport') {
      gridInstance.hideColumns(['Action', 'Status']);
      gridInstance.showSpinner();
      gridInstance.excelExport();
    }
    if (gridInstance && args.item.id === 'grid_pdfexport') {
      selectedData.length > 0 && onClickEvent(selectedData);
    }
  };

  const statusTemplate = (args) => {
    return (
      <div>
        {Object.values(args).includes('active') ? (
          <div
            id="Status"
            // style={{
            //   backgroundColor: '#E6E6F2',
            //   display: 'flex',
            //   borderRadius: '16px',
            //   justifyContent: 'space-evenly',
            //   padding: '6px 4px',
            // }}
            className="statustemp bg-[#E6E6F2] rounded-2xl w-[5.3rem] flex justify-evenly px-1 py-[6px]"
          >
            <img src={approve} className="object-contain"></img>
            <span className="statustxt text-[#4444fb] ">{'Active'}</span>
          </div>
        ) : Object.values(args).includes('pending') ? (
          <div
            id="status"
            className="statustemp bg-[#FFFF77] rounded-2xl w-[5.3rem] flex items-center justify-evenly px-1 py-[6px]"
          >
            <img src={pending} className="object-contain"></img>
            <span className="statustxt text-[#787800] font-medium">{'Pending'}</span>
          </div>
        ) : Object.values(args).includes('Completed') ? (
          <div id="status" className="statustemp bg-[#c3f2c6] rounded-2xl w-[5.3rem] flex justify-evenly px-1 py-[6px]">
            <img src={pending} className="object-contain"></img>
            <span className="statustxt text-[#163c0f] font-medium">{'Completed'}</span>
          </div>
        ) : (
          <div id="status" className="statustemp bg-[#F8C4C4] rounded-2xl w-[5.3rem] flex justify-evenly px-1 py-[6px]">
            <img src={reject} className="object-contain"></img>
            <span className="statustxt text-[#B90000] font-medium">{'Inactive'}</span>
          </div>
        )}
      </div>
    );
  };

  const imageTemplate = (args) => {
    return (
      <div className="cursor-pointer" onClick={() => handleOpen(args?.Image)}>
        <ImageIcon color="primary" />
      </div>
    );
  };
  const discriptionTemplate = (args) => {
    return (
      <div className="cursor-pointer" onClick={() => handleOpen2(args?.description)}>
        <p className="font-sans font-semibold">{args?.description}</p>
      </div>
    );
  };

  const wrapSettings = { wrapMode: 'Content' };
  // const wrapSettingsHader: TextWrapSettingsModel = {wrapMode: 'Header'}
  return (
    <div
      className="w-7xl font-[Poppins] "
      style={{
        backgroundColor: 'white',
        border: '1px solid #DFD7BF',
        padding: '2px',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            onClick={handleClose}
            className="absolute right-2 top-2 cursor-pointer hover:bg-slate-200 duration-300 rounded-full"
          >
            <CloseIcon />
          </div>

          <ImageList sx={{ width: 500, minHeight: 400 }} cols={imageData.length === 1 ? 1 : 2} rowHeight={164}>
            {imageData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  // alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            onClick={handleClose2}
            className="absolute right-2 top-2 cursor-pointer hover:bg-slate-200 duration-300 rounded-full"
          >
            <CloseIcon />
          </div>

          <p className="font-sans font-semibold ">{dis}</p>
        </Box>
      </Modal>
      <GridComponent
        dataSource={data}
        created={created}
        id="grid"
        ref={(grid) => setGridInstnce(grid)}
        // allowExcelExport={true}
        // excelExportComplete={()=> gridInstance && (gridInstance?.hideSpinner(),gridInstance?.showColumns(['Action','Status']))}
        // height={styleProps}
        toolbarClick={clickHandler}
        style={{ zIndex: 1 }}
        selectionSettings={{ checkboxOnly: true }}
        filterSettings={filterOptions}
        allowFiltering={isFilter}
        allowPaging={pagingTool}
        pageSettings={pageOptions}
        allowSorting={isFilter}
        dataBound={dataBound}
        allowResizing={true}
        allowTextWrap={wrap && true}
        textWrapSettings={wrap && wrapSettings}
        enablePersistence={true}
        rowDataBound={rowDataBound}
        rowSelected={(e) => onCheckboxSelect(e)}
        rowDeselected={(e) => onCheckboxDeselect(e)}
        rowHeight={45}
        toolbar={empGrid && ['Search']}
        showColumnChooser={true}
        // actionBegin={actionBegin}
        //@ts-ignore
        editSettings={editSettings}
        className={`w-[${smW}] sm:w-full`}
        // height={'500px'}
      >
        <ColumnsDirective>
          {checkBox && (
            <ColumnDirective
              type="checkbox"
              allowSorting={false}
              allowFiltering={false}
              width="70"
              // displayAsCheckBox={true}
            />
          )}

          {customHeader &&
            customHeader.map((e, key) =>
              e === 'Status' ? (
                <ColumnDirective field="Status" headerText={e} template={statusTemplate} width="130" />
              ) : e === 'Id' ? (
                <ColumnDirective
                  field="Id"
                  headerText={e}
                  // template={statusTemplate}
                  width="80"
                />
              ) : e === 'Listing Title' && isView ? (
                <ColumnDirective
                  field="Listing Title"
                  dataSource={data}
                  template={actionViewHandler}
                  // width="300"
                  textAlign="Left"
                  allowFiltering={false}
                />
              ) : e === 'Ad Entity' && isView ? (
                <ColumnDirective
                  field="Ad Entity"
                  dataSource={data}
                  template={actionViewHandler2}
                  // width="300"
                  textAlign="Left"
                  allowFiltering={false}
                />
              ) : e === 'description' ? (
                <ColumnDirective field="description" headerText={e} template={discriptionTemplate} width="200" />
              ) : e === 'Image' ? (
                // <ColumnDirective field="Image" headerText={e} template={imageTemplate} width="120" />
                null
              // ) : e === 'Email' ? (
              //   null
              // ) : e === 'Phone' ? (
              //   null
              // ) : e === 'Address' ? (
              //   null
              // ) : e === 'Zipcode' ? (
              //   null
              ) : (
                !HeaderNotShow.includes(e) && <ColumnDirective field={`${e}`} allowFiltering={true} width="180" />
              )
            )}
          <ColumnDirective
            field="Active"
            dataSource={data}
            template={actionHandler}
            width="150"
            textAlign="Center"
            allowFiltering={false}
          />
          {/* {isView && (
            <ColumnDirective
              field="View"
              dataSource={data}
              template={actionViewHandler}
              width="100"
              textAlign="Center"
              allowFiltering={false}
            />
          )} */}
        </ColumnsDirective>
        <Inject services={[Selection, Page, Sort, Resize, Filter, Toolbar, ColumnChooser, ForeignKey]} />
      </GridComponent>
    </div>
  );
};

export default DataGrid;
